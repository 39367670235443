import { createContext, ReactNode, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { useFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { SearchContext } from '@f_qwep/context/Search_context';
import _ from 'lodash';
import { FilterStatusEnum } from '@f_qwep/components/filters/filterLeftPanel/types/filterStatus.enum';
import { changeFieldFilterStorage } from '@f_qwep/utils/changeFieldFilterStorage';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';
import { FilterContext } from '@f_qwep/context/Filter_context';

// TODO в эффектах используются костыли - стейты из провайдера - как перепишу фильтры - надо будет исправить на использование mobx
const FilterTemplateContext = createContext<boolean | null>(null);

const FilterTemplateProvider = observer(({ children, type }: { children: ReactNode; type: FilterTypesEnum }) => {
    const { draftFilterSearch } = useContext(QwepContext);

    const store = useFilterLeftPanelStore();

    //Для отслеживания в каком продукте находится фильтр
    store.filterLeft.setFilterType(type);
    const { setCurrentTemplateFilters, setCurrentFilter, activeTemplateId, currentFilter, setFilterStatus } =
        store.filterLeftTemplate;

    const { tabId, filter, setSearchFiltersInitalState, searchFiltersDefaultInitialState, setFilter } =
        useContext(SearchContext);

    const { filterBatchPricer, setFilterBatchPricer } = useContext(FilterContext);

    // Записываем в стор текущие шаблоны
    useEffect(() => {
        if (draftFilterSearch && draftFilterSearch.length) {
            setCurrentTemplateFilters(draftFilterSearch);
        }
    }, [draftFilterSearch]);

    if (type === FilterTypesEnum.SEARCH_PRICER) {
        // Записываем в стор текущий фильтр
        useEffect(() => {
            if (filter && tabId) {
                setCurrentFilter(filter[tabId]);
            }
        }, [filter, tabId]);

        //Установка фильтров если шаблон активен
        useEffect(() => {
            if (activeTemplateId) {
                const currentTemplate = draftFilterSearch.find((filter: any) => filter.id === activeTemplateId);

                if (currentTemplate) {
                    const newFilter = changeFieldFilterStorage(filter, currentTemplate.filters[0]);
                    const isEqual = _.isEqual(filter[tabId], searchFiltersDefaultInitialState);
                    isEqual && setFilter(newFilter);
                }
            }
        }, [draftFilterSearch, activeTemplateId, filter, tabId]);

        // Костьль - сравнение объектов и установка статуса для ui
        useEffect(() => {
            if (currentFilter) {
                if (!activeTemplateId) {
                    const isEqual = _.isEqual(filter[tabId], searchFiltersDefaultInitialState);
                    setFilterStatus(isEqual ? FilterStatusEnum.INITIAL : FilterStatusEnum.CHANGED);
                } else {
                    const currentTemplate = draftFilterSearch.find((filter: any) => filter.id === activeTemplateId);

                    if (currentTemplate) {
                        const isEqual = _.isEqual(filter[tabId], currentTemplate.filters[0]);
                        setSearchFiltersInitalState(currentTemplate.filters[0]);
                        setFilterStatus(isEqual ? FilterStatusEnum.TEMPLATE : FilterStatusEnum.CHANGED_TEMPLATE);
                    }
                }
            }
        }, [filter, tabId, activeTemplateId, draftFilterSearch, setSearchFiltersInitalState]);
    }

    if (type === FilterTypesEnum.BATCH_PRICER) {
        // Записываем в стор текущий фильтр
        useEffect(() => {
            if (filterBatchPricer) {
                setCurrentFilter(filterBatchPricer);
            }
        }, [filterBatchPricer]);

        //Установка фильтров если шаблон активен
        useEffect(() => {
            if (activeTemplateId) {
                const currentTemplate = draftFilterSearch.find((filter: any) => filter.id === activeTemplateId);

                if (currentTemplate) {
                    const newFilter = currentTemplate.filters[0];
                    const isEqual = _.isEqual(filterBatchPricer, searchFiltersDefaultInitialState);
                    isEqual && setFilterBatchPricer(newFilter);
                }
            }
        }, [draftFilterSearch, activeTemplateId, filterBatchPricer]);

        // Костьль - сравнение объектов и установка статуса для ui
        useEffect(() => {
            if (currentFilter) {
                if (!activeTemplateId) {
                    const isEqual = _.isEqual(filterBatchPricer, searchFiltersDefaultInitialState);
                    setFilterStatus(isEqual ? FilterStatusEnum.INITIAL : FilterStatusEnum.CHANGED);
                } else {
                    const currentTemplate = draftFilterSearch.find((filter: any) => filter.id === activeTemplateId);

                    if (currentTemplate) {
                        const isEqual = _.isEqual(filterBatchPricer, currentTemplate.filters[0]);
                        setSearchFiltersInitalState(currentTemplate.filters[0]);
                        setFilterStatus(isEqual ? FilterStatusEnum.TEMPLATE : FilterStatusEnum.CHANGED_TEMPLATE);
                    }
                }
            }
        }, [filterBatchPricer, activeTemplateId, draftFilterSearch, setSearchFiltersInitalState]);
    }

    return <FilterTemplateContext.Provider value={true}>{children}</FilterTemplateContext.Provider>;
});

export { FilterTemplateProvider };
