import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';

export function ColumnUser ({ firstName, lastName, alias, token, index } :any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        <Tooltip
            title={
                firstName
                    ? firstName + ' ' + lastName
                    : alias
                        ? alias
                        : token
            }>
            <div
                className={stls.cell}
                style={{
                    maxHeight: '50px',
                    textOverflow: 'ellipsis',
                    color:
                        idRow !== index && isOpenDetails
                            ? colors.blurColor
                            : colors.omicron,
                }}>
                {firstName
                    ? firstName + ' ' + lastName
                    : alias
                        ? alias
                        : token}
            </div>
        </Tooltip>
    )
}
