import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';
import stls
    from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { ColumnDef } from '@tanstack/react-table';
import {
    ColumnTime,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnTime';
import {
    ColumnUser,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnUser';
import {
    ColumnBrand,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnBrand';
import {
    ColumnArticle,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnArticle';
import {
    ColumnTitle,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnTitle';
import {
    ColumnAvarageBill,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnAvarageBill';
import {
    ColumnPerfectPrice,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnPerfectPrice';
import {
    ColumnPerDeviations,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnPerDeviations';
import {
    ColumnVendor,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnVendor';
import {
    ColumnQuantity,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnQuantity';
import {
    ColumnDelivery,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnDelivery';
import {
    ColumnWarehouse,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnWarehouse';
import {
    ColumnMore,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnMore';
import {
    SuitItems,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/SuitItemsTable';
import { useReport } from '@f_ucs/context/report_context';

export function ReportTable() {

    const { setBestOffersForSusBuys, bestOffersForSusBuys, timeCutReports, susBuys, exportExcelHandler } = useReport();


    const { colors } = useContext(ThemeContext);
    const { width } = useWindowSize();
    const [mobile, setMobile] = useState(false);
    const [idRow, setIdRow] = useState<number>(-1);
    const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

    useEffect(() => {
        setIsOpenDetails(!isOpenDetails);
    }, [timeCutReports]);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    const handleShowDetails = (
        date: string,
        brand: string,
        article: string,
        title: string,
        price: number,
        vendor: string,
    ) => {
        setIsOpenDetails(!isOpenDetails);
        setBestOffersForSusBuys(
            susBuys.find(
                (el: any) =>
                    el.buyTime === date &&
                    el.brand === brand &&
                    el.article === article &&
                    el.partName === title &&
                    el.price === price &&
                    el.vendor === vendor,
            ),
        );
    };

    const renderCustomSubComponent = ({ row }: any) => {
        return (
            <SuitItems
                suitItems={
                    isOpenDetails && JSON.stringify(row.original?.suitableItems) == JSON.stringify(bestOffersForSusBuys.suitableItems)
                        ? row.original?.suitableItems.map((obj: any, index: number) => {
                            return {
                                ...obj,
                                time: `${row.original?.buyTime + ' | ' + row.original?.time?.slice(11, 16)}`,
                            };
                        })
                        : []
                }
                setIsOpenDetails={setIsOpenDetails}
            />
        );
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            [
                {
                    id: 'Time',
                    accessorKey: 'Time',
                    header: 'Дата',
                    cell: (info) => <ColumnTime buyTime={info.row.original.buyTime} index={info.row.index} idRow={idRow}
                                                isOpenDetails={isOpenDetails} />,
                    size: 70,
                    sortingFn: (a, b) => {
                        const dateA = new Date(b.original.buyTime);
                        const dateB = new Date(a.original.buyTime);
                        return +dateA - +dateB;
                    },
                },
                {
                    id: 'user',
                    accessorKey: 'User',
                    header: 'Сотрудник',
                    cell: (info) => <ColumnUser firstName={info.row.original.user.firstName}
                                                lastName={info.row.original.user.lastName}
                                                alias={info.row.original.user.alias} token={info.row.original.token}
                                                index={info.row.index} idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 100,
                },
                {
                    id: 'Brand',
                    accessorKey: 'Brand',
                    header: 'Бренд',
                    cell: (info) => <ColumnBrand index={info.row.index} brand={info.row.original?.brand} idRow={idRow}
                                                 isOpenDetails={isOpenDetails} />,
                    size: 100,
                },
                {
                    id: 'Article',
                    accessorKey: 'Article',
                    header: `Артикул`,
                    cell: (info) => <ColumnArticle index={info.row.index} article={info.row.original?.article}
                                                   idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 130,
                },
                {
                    id: 'Title',
                    accessorKey: 'Title',
                    header: `Название`,
                    cell: (info) => <ColumnTitle partName={info.row.original.partName} index={info.row.index}
                                                 idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 250,
                },
                {
                    id: 'AvarageBill',
                    accessorKey: 'AvarageBill',
                    header: `Цена покупки`,
                    cell: (info) => <ColumnAvarageBill suitItemsLength={info.row.original.suitableItems?.length}
                                                       price={info.row.original.price} index={info.row.index}
                                                       idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 100,
                    sortingFn: (a: any, b: any) => b.original.price - a.original.price,
                },
                {
                    id: 'PerfectPrice',
                    accessorKey: 'PerfectPrice',
                    header: `Лучшая цена`,
                    cell: (info) => <ColumnPerfectPrice original={info.row.original} index={info.row.index}
                                                        idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 100,
                },
                {
                    id: 'PerDeviations',
                    accessorKey: 'PerDeviations',
                    header: `% отклонений`,
                    cell: (info) => <ColumnPerDeviations suitItemsLength={info.row.original.suitableItems?.length}
                                                         suitItems={info.row.original.suitableItems}
                                                         index={info.row.index}
                                                         suitItemsPrice={info.row.original.suitableItems[0]?.price}
                                                         price={info.row.original.price} idRow={idRow}
                                                         isOpenDetails={isOpenDetails} />,
                    size: 100,
                    sortingFn: (a: any, b: any) =>
                        100 -
                        (b.original.suitableItems[0]?.price / b.original.price) * 100 -
                        (100 - (a.original.suitableItems[0]?.price / a.original.price) * 100),
                },
                {
                    id: 'Vendor',
                    accessorKey: 'Vendor',
                    header: `Поставщик`,
                    cell: (info) => <ColumnVendor vendorLength={info.row.original.vendor.length} index={info.row.index}
                                                  vendor={info.row.original.vendor} idRow={idRow}
                                                  isOpenDetails={isOpenDetails} />,
                    size: 150,
                },
                {
                    id: 'Quantity',
                    accessorKey: 'Quantity',
                    header: `Количество`,
                    cell: (info) => <ColumnQuantity index={info.row.index} quantity={info.row.original.quantity}
                                                    idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 100,
                    sortingFn: (a: any, b: any) => b.original.quantity - a.original.quantity,
                },
                {
                    id: 'Delivery',
                    accessorKey: 'Delivery',
                    header: 'Срок',
                    cell: (info) => <ColumnDelivery deliveryLength={info.row.original.delivery?.length}
                                                    delivery={info.row.original.delivery} index={info.row.index}
                                                    idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 120,
                },
                {
                    id: 'Warehouse',
                    accessorKey: 'Warehouse',
                    header: `Склад`,
                    cell: (info) => <ColumnWarehouse index={info.row.index} warehouse={info.row.original.warehouse}
                                                     idRow={idRow} isOpenDetails={isOpenDetails} />,
                    size: 120,
                },
                {
                    id: 'more',
                    accessorKey: 'more',
                    header: '',
                    cell: (info) => <ColumnMore index={info.row.index} buyTime={info.row.original.buyTime}
                                                brand={info.row.original.brand} article={info.row.original.article}
                                                partName={info.row.original.partName} price={info.row.original.price}
                                                vendor={info.row.original.vendor} setIdRow={setIdRow}
                                                isOpenDetails={isOpenDetails} handleShowDetails={handleShowDetails} />,
                    size: 60,
                },
            ],

        [susBuys.length, isOpenDetails, bestOffersForSusBuys, idRow],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            base: true,
            title: {
                fontWeight: 'bold',
                fontSize: '14px',
                textWrap: 'balance',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            inputFilter: {
                // borderRadius: '7px'
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '50px',
                maxHeight: '50px',
                fontSize: '16px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div className={stls.container} style={{ background: colors.theta }}>
            <div className={stls.head}>
                <p className={stls.title}>Отчет активности</p>
            </div>
            <VirtualTable
                // searchId={tabId}
                data={susBuys}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={false}
                renderCustomSubComponent={isOpenDetails ? renderCustomSubComponent : false}
                configHeader={{
                    fullScrean: true,
                    exportExcel: true,
                    resize: true,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: false,
                    dragging: true,
                }}
                styles={tableStyles}
                overscan={2}
                estimateSize={mobile ? 191 : 39}
                exportExcelHandler={() => exportExcelHandler('suspiciousBuys')}
            />
        </div>
    );
}
