import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { ClickAwayListener, Tabs, Tab } from '@mui/material';
import { useCollector } from '../../context/collector_context';
import Box from '@mui/material/Box';
import stls from '@f_ucs/styles/components/dataSelectorPanel/dataSelectorPanel.module.sass';
import { DateRangePicker } from './DateRangeModul';
import { useDatePicker } from '@f_ucs/context/date_picker_context';
import { useWindowSize } from '@f_hooks';
import { formatDateFromString } from '@f_ucs/utils/formatDateFromString';

export function DateSelectorPanel() {
    const { loading } = useCollector();
    const { colors, currentTheme } = useContext(ThemeContext);
    const { showDatePicker, setShowDatePicker, currentTime, setCurrentTime, updateFilteredReport, dateRange } =
        useDatePicker();
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();
    useEffect(() => {
        setMobile(width < 900);
    }, [width]);

    const changeHandler = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTime(newValue);
        updateFilteredReport(newValue);
    };

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleClickAway = () => {
        setShowDatePicker(false);
    };

    return (
        <>
            {loading ? (
                ''
            ) : (
                <div className={stls.dataSelector}>
                    <Box sx={{ borderColor: 'divider' }}>
                        <Tabs
                            value={currentTime}
                            onChange={changeHandler}
                            className={stls.tabs}
                            aria-label={`${colors.omicron} tabs example`}
                            textColor={colors.omicron}
                            orientation={mobile ? 'vertical' : 'horizontal'}>
                            <Tab
                                label="Вчера"
                                value="1"
                                sx={{
                                    '&:hover': { color: colors.alpha },
                                    '&.Mui-selected': { color: colors.alpha, fontWeight: 'bold' },
                                    textTransform: 'none',
                                }}
                            />
                            <Tab
                                label="Последние 7 дней"
                                value="2"
                                sx={{
                                    '&:hover': { color: colors.alpha },
                                    '&.Mui-selected': { color: colors.alpha, fontWeight: 'bold' },
                                    textTransform: 'none',
                                }}
                            />
                            <Tab
                                label="Последние 30 дней"
                                value="3"
                                sx={{
                                    '&:hover': { color: colors.alpha },
                                    '&.Mui-selected': { color: colors.alpha, fontWeight: 'bold' },
                                    textTransform: 'none',
                                }}
                            />
                            {/*<Tab
                                label={
                                    <div
                                        className={`${stls.dateRangeContainer} ${
                                            currentTime === 'custom' ? stls.selected : ''
                                        }`}>
                                        <div className={stls.title}>Диапазон дат</div>
                                        <div className={currentTheme === 'dark' ? stls.rangeDark : stls.rangeLight}>
                                            {formatDateFromString((dateRange[0].startDate)?.toString())} : {formatDateFromString((dateRange[0].endDate)?.toString())}
                                        </div>
                                    </div>
                                }
                                value="custom"
                                onClick={toggleDatePicker}
                                sx={{
                                    '&:hover': { color: colors.alpha },
                                    '&.Mui-selected': { color: colors.alpha, fontWeight: 'bold' },
                                    textTransform: 'none',
                                    position: 'relative',
                                }}
                            />*/}
                            <Tab
                                label="За всё время"
                                value="4"
                                sx={{
                                    '&:hover': { color: colors.alpha },
                                    '&.Mui-selected': { color: colors.alpha, fontWeight: 'bold' },
                                    textTransform: 'none',
                                }}
                            />
                        </Tabs>
                    </Box>
                    {showDatePicker && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <div>
                                <DateRangePicker />
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
            )}
        </>
    );
}
