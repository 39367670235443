import React, { useMemo, useContext, FC } from 'react';
import cn from 'classnames';

import stls from '@f_styles/general/table/TableHeader.module.sass';

import { flexRender, Header, Table, Column, ColumnOrderState, ColumnResizeMode } from '@tanstack/react-table';

import { useDrag, useDrop } from 'react-dnd';
import Tooltip from '@mui/material/Tooltip';

import { IconUpTable } from '@f_components/icons/IconUpTable';
import { IconDownTable } from '@f_components/icons/IconDownTable';
import { IconUpDownTable } from '@f_components/icons/IconUpDownTable';
import { IconGroupsTable } from '@f_components/icons/IconGroupsTable';
import { TableFilter } from '@f_general/stackTable/TableFilters';
import { TypeTables } from '@f_types/TypeTables';
import { colors } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';

const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]): ColumnOrderState => {
    columnOrder.splice(
        columnOrder.indexOf(targetColumnId),
        0,
        columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string,
    );
    return [...columnOrder];
};

export const TableHeader: FC<{
    header: Header<TypeTables, unknown>;
    table: Table<TypeTables>;
    columnResizeMode: ColumnResizeMode;
    configHeader: any;
    columnSearchAction: boolean;
    styles: any;
    searchId?: any;
}> = ({ header, table, searchId, columnResizeMode, configHeader, columnSearchAction, styles }) => {
    const { getState, setColumnOrder } = table;
    const { columnOrder } = getState();
    const { column } = header;
    const { colors, currentTheme } = useContext(ThemeContext);

    const [, dropRef] = useDrop({
        accept: 'column',
        drop: (draggedColumn: Column<TypeTables>) => {
            const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
            setColumnOrder(newColumnOrder);
        },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: 'column',
    });

    return (
        <th
            ref={dropRef}
            {...{
                key: header.id,
                colSpan: header.colSpan,
                className: styles.head.base ? stls.th_r : stls.th,
                style: {
                    background: currentTheme === 'vinpin' ? colors.iota : colors.beta,
                    width: header.getSize() < 70 ? '50px' : header.getSize(),
                    opacity: isDragging ? 0.5 : 1,
                    color: currentTheme === 'vinpin' ? colors.tau : colors.omicrom,
                },
            }}>
            <Tooltip title={flexRender(header.column.columnDef.header, header.getContext())} placement="bottom">
                <div style={styles.head?.container} className={stls.container}>
                    <div ref={previewRef} className={stls.row}>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            {header.isPlaceholder ? null : configHeader.dragging ? (
                                <Tooltip title={'Перетащить'} placement="top">
                                    <p
                                        id={`${header.column.columnDef.header}`}
                                        {...{
                                            className: `${header.column.getCanSort()} ${stls.filter}`,
                                            onClick: header.column.getToggleSortingHandler(),
                                            colSpan: header.colSpan,
                                        }}
                                        className={cn(stls.title, stls.active)}
                                        ref={dragRef}
                                        style={{ ...styles.head.title, color: colors.omicronTitle }}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </p>
                                </Tooltip>
                            ) : (
                                <p style={{ ...styles.head.title, color: colors.omicronTitle }} className={stls.title}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </p>
                            )}
                            {configHeader.sorting &&
                                flexRender(header.column.columnDef.header, header.getContext()) && (
                                    <div
                                        {...{
                                            className: `${header.column.getCanSort()} ${stls.filter}`,
                                            onClick: header.column.getToggleSortingHandler(),
                                            colSpan: header.colSpan,
                                        }}>
                                        {{
                                            asc: <IconUpTable classNames={[stls.activeIcon]} />,
                                            desc: <IconDownTable classNames={[stls.activeIcon]} />,
                                        }[header.column.getIsSorted() as string] ?? (
                                            <IconUpDownTable classNames={[stls.icon]} />
                                        )}
                                    </div>
                                )}
                        </div>

                        <div style={{ display: 'flex' }}>
                            {configHeader.grouping &&
                                flexRender(header.column.columnDef.header, header.getContext()) && (
                                    <>
                                        {header.column.getCanGroup() ? (
                                            <div
                                                {...{
                                                    onClick: header.column.getToggleGroupingHandler(),
                                                    className: stls.filter,
                                                }}>
                                                {header.column.getIsGrouped() ? (
                                                    <IconGroupsTable classNames={[stls.activeIcon]} />
                                                ) : (
                                                    <IconGroupsTable classNames={[stls.icon]} />
                                                )}
                                            </div>
                                        ) : null}{' '}
                                    </>
                                )}
                        </div>

                        {configHeader.resize && (
                            <div
                                {...{
                                    onMouseDown: header.getResizeHandler(),
                                    onTouchStart: header.getResizeHandler(),
                                    className: `${stls.resizer} ${header.column.getIsResizing()}`,
                                    style: {
                                        transform:
                                            columnResizeMode === 'onEnd' && header.column.getIsResizing()
                                                ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                                                : '',
                                    },
                                }}
                            />
                        )}
                    </div>

                    {header.column.id != 'Цена' &&
                    header.column.id != 'Срок' &&
                    header.column.id != 'Наличие' &&
                    header.column.id != 'Рейтинг' &&
                    header.column.id != 'Подтверждение' &&
                    columnSearchAction &&
                    flexRender(header.column.columnDef.header, header.getContext()) &&
                    header.column.getCanFilter() ? (
                        <div id={header.column.id} className={stls.tableFilterPanel}>
                            <TableFilter searchId={searchId} column={header.column} table={table} styles={styles} />
                        </div>
                    ) : null}
                </div>
            </Tooltip>
        </th>
    );
};
