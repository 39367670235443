import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useGetDeals } from '@f_qwep/components/purchasingAssistant/model/hooks/useGetDeals';
import { UserContext } from '@f_context/User_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { observer } from 'mobx-react-lite';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';

const AssistantContext = createContext<boolean | null>(null);

const AssistantProvider = observer(({ children }: { children: ReactNode }) => {
    const store = useAssistantStore();

    const { isAccess: access } = useAccess(QwepProductEnum.ASSISTANT);
    // Обновление текущего списка
    const { users } = useContext(UserContext);
    const intervalMs = 60000;
    const { mutate: getDeals } = useGetDeals(store);
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (users.length && access) {
            getDeals(AlertStatusEnum.BASE);
            intervalId = setInterval(() => {
                getDeals(AlertStatusEnum.UPDATE);
            }, intervalMs);
        }

        return () => clearInterval(intervalId);
    }, [users, access]);

    // обновление списка ассистента при изменении текущего ассистента
    const { getProperty } = useContext(QwepContext);
    const { currentAssistantList } = store.assistantList;
    useEffect(() => {
        getProperty();
    }, [currentAssistantList]);

    // Обновление отфильтрованного списка
    const { isActiveLow, isActiveMedium, isActiveHard, isVendorsForDating } = store.assistantFilters;
    const { filterDeals, deals } = store.assistantDeals;

    useEffect(() => {
        filterDeals();
    }, [deals, isVendorsForDating, isActiveLow, isActiveMedium, isActiveHard]);

    return <AssistantContext.Provider value={access}>{children}</AssistantContext.Provider>;
});

export { AssistantProvider, AssistantContext };
