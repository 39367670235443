import { createContext, useContext, useState } from 'react';
import { SearchContext } from './Search_context';
import dayjs from 'dayjs';
import { changeFieldFilterStorage } from '@f_qwep/utils/changeFieldFilterStorage';
import { IFilter } from '@f_qwep/components/filters/filterLeftPanel/types/filters.interface';
import { OrderStatuses } from '@f_qwep/components/orders/utils/orderStatuses.util';

const FilterContext = createContext<any>({});

interface IOrderFilterState {
    brands: string[];
    vendorTitle: string[];
    status: string[];
    minDate: string;
    maxDate: string;
}

const orderFiltersInitialState: IOrderFilterState = {
    brands: [],
    vendorTitle: [],
    status: [],
    minDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    maxDate: dayjs().format('YYYY-MM-DD'),
};

function FilterProvider(props: any) {
    const {
        tabId,
        filter,
        setFilter,
        searchFiltersInitalState,
        setSearchFiltersInitalState,
        searchFiltersDefaultInitialState,
    } = useContext(SearchContext);

    const [basketFiltersInitalState, setBasketFiltersInitalState] = useState({
        brands: [],
        vendorTitle: [],
    });

    const [isFirstLoadOrder, setisFirstLoadOrder] = useState<boolean>(false);

    const [filterBatchPricer, setFilterBatchPricer] = useState<IFilter>(searchFiltersDefaultInitialState);

    const [filterBasket, setFilterBasket] = useState<any>(basketFiltersInitalState);

    //Управление фильтрами заказов
    const [filterOrder, setFilterOrder] = useState<any>(orderFiltersInitialState);
    const [isActiveOrderStatus, setActiveOrderStatus] = useState<boolean>(false);

    // Управление глобальными фильтрами артикулов, наличия и кроссов
    const [isActiveArticle, setIsActiveArticle] = useState<boolean>(false);
    const [isActiveStock, setIsActiveStock] = useState<boolean>(false);
    const [isActiveCross, setIsActiveCross] = useState<boolean>(false);
    const [isDealer, setIsDealer] = useState<boolean>(false);

    const searchFiltersReducer = (action: any) => {
        const state = filter[tabId];
        const { type, payload } = action;

        switch (type) {
            case 'search_article_toggle':
                setIsActiveArticle(!state.searchArticle);
                setSearchFiltersInitalState({
                    ...searchFiltersInitalState,
                    searchArticle: !state.searchArticle,
                    inStock: isActiveStock,
                    approvedCross: isActiveCross,
                    isDealer: isDealer,
                });
                const newFilerWithArticleToggle = changeFieldFilterStorage(filter, {
                    searchArticle: !state.searchArticle,
                });
                setFilter(newFilerWithArticleToggle);
                break;
            case 'in_stock_toggle':
                setIsActiveStock(!state.inStock);
                setSearchFiltersInitalState({
                    ...searchFiltersInitalState,
                    searchArticle: isActiveArticle,
                    inStock: !state.inStock,
                    approvedCross: isActiveCross,
                    isDealer: isDealer,
                });
                const newFilerWithStockToggle = changeFieldFilterStorage(filter, { inStock: !state.inStock });
                setFilter(newFilerWithStockToggle);
                break;
            case 'approved_cross_vendor_toggle':
                setIsActiveCross(!state.approvedCross);
                setSearchFiltersInitalState({
                    ...searchFiltersInitalState,
                    searchArticle: isActiveArticle,
                    inStock: isActiveStock,
                    approvedCross: !state.approvedCross,
                    isDealer: isDealer,
                });
                const newFilerWithCrossVendorToggle = changeFieldFilterStorage(filter, {
                    approvedCross: !state.approvedCross,
                });
                setFilter(newFilerWithCrossVendorToggle);
                break;
            case 'isDealer_toggle':
                setIsDealer(!state.isDealer);

                setSearchFiltersInitalState({
                    ...searchFiltersInitalState,
                    searchArticle: isActiveArticle,
                    inStock: isActiveStock,
                    approvedCross: isActiveCross,
                    isDealer: !state.isDealer,
                });

                const newFilerWithIsDealerToggle = changeFieldFilterStorage(filter, {
                    isDealer: !state.isDealer,
                });
                setFilter(newFilerWithIsDealerToggle);
                break;
            case 'brands_toggle':
                if (state.brands.includes(payload.brand)) {
                    return setFilter({
                        ...filter,
                        [tabId]: { ...state, brands: state.brands.filter((item: any) => item !== payload.brand) },
                    });
                }
                return setFilter({ ...filter, [tabId]: { ...state, brands: [...state.brands, payload.brand] } });

            case 'vendors_toggle':
                if (state.vendorTitle.includes(payload.vendor)) {
                    return setFilter({
                        ...filter,
                        [tabId]: {
                            ...state,
                            vendorTitle: state.vendorTitle.filter((item: any) => item !== payload.vendor),
                        },
                    });
                }
                return setFilter({
                    ...filter,
                    [tabId]: { ...state, vendorTitle: [...state.vendorTitle, payload.vendor] },
                });

            case 'minPrice_input':
                return setFilter({ ...filter, [tabId]: { ...state, minPrice: payload.min } });
            case 'maxPrice_input':
                return setFilter({ ...filter, [tabId]: { ...state, maxPrice: payload.max } });

            case 'minShipmentDays_input':
                return setFilter({ ...filter, [tabId]: { ...state, minShipmentDays: payload.minDays } });

            case 'maxShipmentDays_input':
                return setFilter({ ...filter, [tabId]: { ...state, maxShipmentDays: payload.maxDays } });

            case 'minQuantity_input':
                return setFilter({ ...filter, [tabId]: { ...state, minQuantity: payload.minQuantity } });
            case 'maxQuantity_input':
                return setFilter({ ...filter, [tabId]: { ...state, maxQuantity: payload.maxQuantity } });

            case 'warehouses_toggle':
                if (state.warehouses.includes(payload.warehouse)) {
                    return setFilter({
                        ...filter,
                        [tabId]: {
                            ...state,
                            warehouses: state.warehouses.filter((item: any) => item !== payload.warehouse),
                        },
                    });
                }
                return setFilter({
                    ...filter,
                    [tabId]: { ...state, warehouses: [...state.warehouses, payload.warehouse] },
                });
            case 'reset_filters':
                setIsActiveArticle(false);
                setIsActiveStock(false);
                setIsActiveCross(false);
                setIsDealer(false);
                setSearchFiltersInitalState(searchFiltersDefaultInitialState);
                const nullifyFilter = changeFieldFilterStorage(filter, {
                    ...searchFiltersDefaultInitialState,
                });
                setFilter(nullifyFilter);
                break;
            default:
                throw new Error();
        }
    };

    const batchPricerFiltersReducer = (action: any) => {
        const { type, payload } = action;

        switch (type) {
            case 'search_article_toggle':
                setFilterBatchPricer({ ...filterBatchPricer, searchArticle: !filterBatchPricer.searchArticle });
                break;
            case 'in_stock_toggle':
                setFilterBatchPricer({ ...filterBatchPricer, inStock: !filterBatchPricer.inStock });
                break;
            case 'approved_cross_vendor_toggle':
                setFilterBatchPricer({ ...filterBatchPricer, approvedCross: !filterBatchPricer.approvedCross });
                break;
            case 'isDealer_toggle':
                setFilterBatchPricer({ ...filterBatchPricer, isDealer: !filterBatchPricer.isDealer });
                break;
            case 'brands_toggle':
                if (filterBatchPricer.brands.includes(payload.brand)) {
                    return setFilterBatchPricer({
                        ...filterBatchPricer,
                        brands: filterBatchPricer.brands.filter((item: any) => item !== payload.brand),
                    });
                }
                return setFilterBatchPricer({
                    ...filterBatchPricer,
                    brands: [...filterBatchPricer.brands, payload.brand],
                });

            case 'vendors_toggle':
                if (filterBatchPricer.vendorTitle.includes(payload.vendor)) {
                    return setFilterBatchPricer({
                        ...filterBatchPricer,
                        vendorTitle: filterBatchPricer.vendorTitle.filter((item: any) => item !== payload.vendor),
                    });
                }
                return setFilterBatchPricer({
                    ...filterBatchPricer,
                    vendorTitle: [...filterBatchPricer.vendorTitle, payload.vendor],
                });

            case 'minPrice_input':
                return setFilterBatchPricer({ ...filterBatchPricer, minPrice: payload.min });
            case 'maxPrice_input':
                return setFilterBatchPricer({ ...filterBatchPricer, maxPrice: payload.max });

            case 'minShipmentDays_input':
                return setFilterBatchPricer({ ...filterBatchPricer, minShipmentDays: payload.minDays });

            case 'maxShipmentDays_input':
                return setFilterBatchPricer({ ...filterBatchPricer, maxShipmentDays: payload.maxDays });

            case 'warehouses_toggle':
                if (filterBatchPricer.warehouses.includes(payload.warehouse)) {
                    return setFilterBatchPricer({
                        ...filterBatchPricer,
                        warehouses: filterBatchPricer.warehouses.filter((item: any) => item !== payload.warehouse),
                    });
                }
                return setFilterBatchPricer({
                    ...filterBatchPricer,
                    warehouses: [...filterBatchPricer.warehouses, payload.warehouse],
                });
            case 'minQuantity_input':
                return setFilterBatchPricer({ ...filterBatchPricer, minQuantity: payload.minQuantity });
            case 'maxQuantity_input':
                return setFilterBatchPricer({ ...filterBatchPricer, maxQuantity: payload.maxQuantity });
            case 'reset_filters':
                setFilterBatchPricer(searchFiltersDefaultInitialState);
                break;
            default:
                throw new Error();
        }
    };

    const basketFiltersReducer = (action: any) => {
        const { type, payload } = action;
        switch (type) {
            case 'brands_toggle':
                if (filterBasket.brands.includes(payload.brand)) {
                    return setFilterBasket({
                        ...filterBasket,
                        brands: filterBasket.brands.filter((item: any) => item !== payload.brand),
                    });
                }
                return setFilterBasket({ ...filterBasket, brands: [...filterBasket.brands, payload.brand] });

            case 'vendors_toggle':
                if (filterBasket.vendorTitle.includes(payload.vendor)) {
                    return setFilterBasket({
                        ...filterBasket,
                        vendorTitle: filterBasket.vendorTitle.filter((item: any) => item !== payload.vendor),
                    });
                }
                return setFilterBasket({ ...filterBasket, vendorTitle: [...filterBasket.vendorTitle, payload.vendor] });

            case 'reset_filters':
                return setFilterBasket(basketFiltersInitalState);
            default:
                throw new Error();
        }
    };

    const orderFiltersReducer = (action: any) => {
        const { type, payload } = action;
        switch (type) {
            case 'brands_toggle':
                if (filterOrder.brands.includes(payload.brand)) {
                    return setFilterOrder({
                        ...filterOrder,
                        brands: filterOrder.brands.filter((item: any) => item !== payload.brand),
                    });
                }
                return setFilterOrder({ ...filterOrder, brands: [...filterOrder.brands, payload.brand] });

            case 'vendors_toggle':
                if (filterOrder.vendorTitle.includes(payload.vendor)) {
                    return setFilterOrder({
                        ...filterOrder,
                        vendorTitle: filterOrder.vendorTitle.filter((item: any) => item !== payload.vendor),
                    });
                }
                return setFilterOrder({ ...filterOrder, vendorTitle: [...filterOrder.vendorTitle, payload.vendor] });

            case 'status_toggle':
                if (payload.triggerActive) {
                    setActiveOrderStatus((prev) => {
                        if (!prev) {
                            const statuses = payload.statuses.filter(
                                (item: any) => !OrderStatuses.closed.includes(item),
                            );
                            setFilterOrder({
                                ...filterOrder,
                                status: statuses,
                            });
                        } else {
                            setFilterOrder({ ...filterOrder, status: [] });
                        }
                        return !prev;
                    });
                } else {
                    if (filterOrder.status.includes(payload.status)) {
                        const newStatus = filterOrder.status.filter((status: string) => status !== payload.status);

                        if (newStatus.length && !newStatus.some((status) => OrderStatuses.closed.includes(status))) {
                            setActiveOrderStatus(true);
                        } else {
                            setActiveOrderStatus(false);
                        }

                        setFilterOrder({
                            ...filterOrder,
                            status: newStatus,
                        });
                    } else {
                        const newStatus = [...filterOrder.status, payload.status];

                        if (newStatus.length && !newStatus.some((status) => OrderStatuses.closed.includes(status))) {
                            setActiveOrderStatus(true);
                        } else {
                            setActiveOrderStatus(false);
                        }

                        setFilterOrder((prev: any) => {
                            return {
                                ...filterOrder,
                                status: newStatus,
                            };
                        });
                    }
                }
                break;

            case 'minDate_input':
                return setFilterOrder({ ...filterOrder, minDate: payload.minDate });
            case 'maxDate_input':
                return setFilterOrder({ ...filterOrder, maxDate: payload.maxDate });

            case 'reset_filters':
                return setFilterOrder(orderFiltersInitialState);
            default:
                throw new Error();
        }
    };

    return (
        <FilterContext.Provider
            value={{
                filter,
                filterBatchPricer,
                filterBasket,
                filterOrder,
                isActiveOrderStatus,

                // methods
                searchFiltersReducer,
                batchPricerFiltersReducer,
                basketFiltersReducer,
                orderFiltersReducer,
                setFilterBatchPricer,
                setActiveOrderStatus,

                //Отслеживает первую загрузку ордеров
                isFirstLoadOrder,
                setisFirstLoadOrder,
            }}
            {...props}
        />
    );
}

export { FilterProvider, FilterContext };
