export const videoContent = {
    shortReview: {
        description: 'QWEP краткий обзор',
        youtube: 'https://www.youtube.com/watch?v=goVNVRWkbV4',
        rutube: 'https://rutube.ru/video/private/3efadeb0e092cf3de4741ff175882723/?p=pX-saU-9cNLhzHUZzErkCQ',
        type: 'private',
    },
    oneCInstallation: {
        description: 'QWEP - установка учебной версии 1С',
        youtube: 'https://www.youtube.com/watch?v=OSnjcHp_VFo',
        rutube: 'https://rutube.ru/video/private/69a4cf2f93b8cf1d9963afd321c6ca58/?p=5PfQkrLnkzv2An1tOazniA',
        type: 'private',
    },
    qwepReview: {
        description: 'Обзор QWEP — продукта для качественного поиска запчастей',
        youtube: 'https://www.youtube.com/watch?v=biUwNiB0rII&t',
        rutube: 'https://rutube.ru/video/3ec414264066ee660375a04e714b21ff/',
        type: 'public',
    },
    firstRun: {
        description: 'QWEP — Первый запуск и авторизация',
        youtube: 'https://www.youtube.com/watch?v=50CfDLxS6r4',
        rutube: 'https://rutube.ru/video/private/e974713b0afa38dc87171ac1eb7649a2/?p=C5cCq9nLTRPgPVP-POZzbQ',
        type: 'private',
    },
    settingsRecovery: {
        description: 'QWEP — Восстановление настроек из файла',
        youtube: 'https://www.youtube.com/watch?v=UIxe5clAEn8',
        rutube: 'https://rutube.ru/video/f8019c466bcc232145006b10f162581a/',
        type: 'public',
    },
    backupCopy: {
        description: 'QWEP — Выгрузка резервной копии настроек в файл',
        youtube: 'https://www.youtube.com/watch?v=_2enyo6zDC4',
        rutube: 'https://rutube.ru/video/967c9aa0f22eace9f2f1d6f3505e5ba2/',
        type: 'public',
    },
    addToCart: {
        description: 'QWEP — Добавление в корзину, пример с коротким артикулом',
        youtube: 'https://www.youtube.com/watch?v=VFQ-BidcBXU&t',
        rutube: 'https://rutube.ru/video/821e38378d0ba921a0b2a400184f5d61/',
        type: 'public',
    },
    suppliersPanel: {
        description: 'QWEP — Панель "Поставщики"',
        youtube: 'https://www.youtube.com/watch?v=dQ2RSSZz47k',
        rutube: 'https://rutube.ru/video/afed7b4ba68c95a4eab3e50803ec7485/',
        type: 'public',
    },
    searchFilterSort: {
        description: 'QWEP — Поиск, фильтрация и сортировка',
        youtube: 'https://www.youtube.com/watch?v=6ShUoq9gzjI&t',
        rutube: 'https://rutube.ru/video/0847aff6e1b31112ebe5eb0dafd5d566/',
        type: 'public',
    },
    searchWithRefinements: {
        description: 'QWEP — Поиск с уточнениями',
        youtube: 'https://www.youtube.com/watch?v=f_R3fq6Tdbc',
        rutube: 'https://rutube.ru/video/cf6251615a210518be01e2b61c463354/',
        type: 'public',
    },
    qwep: {
        description: 'Qwep',
        youtube: 'https://www.youtube.com/watch?v=lJvj4K8CdmM',
        rutube: 'https://rutube.ru/video/52b8b63a0d9a2581fbb52df3270b7eed/',
        type: 'public',
    },
    qwep720: {
        description: 'qwep 720',
        youtube: 'https://www.youtube.com/watch?v=spk-_drjGas',
        rutube: 'https://rutube.ru/video/008b882ddc46f65430ebe06e2521eff8/',
        type: 'public',
    },
    refinementExpansion: {
        description: 'Раскрытие отобранных уточнений',
        youtube: 'https://www.youtube.com/watch?v=FGIisDnDp9A',
        rutube: 'https://rutube.ru/video/8598d79aacc3cf6327424bd3d489a419/',
        type: 'public',
    },
    cityFilter: {
        description: 'Отбор по городам',
        youtube: 'https://www.youtube.com/watch?v=LN_xn_a-uUk',
        rutube: 'https://rutube.ru/video/df826aa981d4e8394d53187b2c890c93/',
        type: 'public',
    },
    ownStock: {
        description: 'Работа со своими остатками',
        youtube: 'https://www.youtube.com/watch?v=NliObR-Al88',
        rutube: 'https://rutube.ru/video/19598b0fa5cda0cefaaa1c497a4ba454/',
        type: 'public',
    },
    supplierGroups: {
        description: 'Группы поставщиков',
        youtube: 'https://www.youtube.com/watch?v=62xKXFDhd9k&t',
        rutube: 'https://rutube.ru/video/c128b12eade1326a8eccd9e87c17c21b/',
        type: 'public',
    },
    markupAndClientMode: {
        description: 'QWEP — Наценка и режим "Клиент"',
        youtube: 'https://www.youtube.com/watch?v=UIxe5clAEn8&t',
        rutube: 'https://rutube.ru/video/8d666ffa7c65b6f8b5cd87b9f1c708cd/',
        type: 'public',
    },
    listPricing: {
        description: 'QWEP — Проценка списком',
        youtube: 'https://www.youtube.com/watch?v=Oi4GBT9FJhM',
        rutube: 'https://rutube.ru/video/b7a40abcd97bc40739239a35cfe11d67/',
        type: 'public',
    },
    qwepUserControlCenter: {
        description: 'Центр контроля пользователей QWEP',
        youtube: 'https://www.youtube.com/watch?v=xIT-_lto-m0',
        rutube: 'https://rutube.ru/video/private/9b6fe39a9dfbbb8fe817c4f4a444a603/?p=nvdCUO4lk6bo2l09EaRq6Q',
        type: 'private',
    },
    ownStockTable: {
        description: 'QWEP — Свои остатки по таблице',
        youtube: 'https://www.youtube.com/watch?v=ORprMB8VtWo',
        rutube: 'https://rutube.ru/video/ac087351edc36d2c67dc82ec71eae58c/',
        type: 'public',
    },
    ownStockQuery: {
        description: 'QWEP — Свои остатки по запросу',
        youtube: 'https://www.youtube.com/watch?v=JttEi-hIUt8&t',
        rutube: 'https://rutube.ru/video/3d109ffed60f945e67dd37181e07e1a9/',
        type: 'public',
    },
    qwepCart: {
        description: 'QWEP — Корзина QWEP',
        youtube: 'https://www.youtube.com/watch?v=86bY2Ar-d1U',
        rutube: 'https://rutube.ru/video/aec46178d279f5ba477ea64fb761ebf9/',
        type: 'public',
    },
    msscriptcontrolScriptcontrol: {
        description: 'Работа компоненты MSScriptControl.ScriptControl в 64-битном приложении',
        youtube: 'https://www.youtube.com/watch?v=xn42t7sKX_o',
        rutube: 'https://rutube.ru/video/private/51a7a642dcd39d8cac3744ce9718c32c/?p=8QmDGqmMsmoiyY_0zwL6vA',
        type: 'private',
    },
    shortReview2: {
        description: 'QWEP — Краткий обзор',
        youtube: 'https://www.youtube.com/watch?v=Eynu49nCqGk',
        rutube: 'https://rutube.ru/video/private/ceeb837b38db5609762a8b81a0e8f785/?p=WLKOS4hgoDy5sKJSp3Ssaw',
        type: 'private',
    },
    qwepCatalogs: {
        description: 'QWEP — Каталоги в QWEP',
        youtube: 'https://www.youtube.com/watch?v=h4xN0GLRSR4',
        rutube: 'https://rutube.ru/video/private/a3dde4e1f7c735dd71591cf503bc17ab/?p=MhtaLYlit9lGovQ6oljd3Q',
        type: 'private',
    },
    qwepCart2: {
        description: 'QWEP — Корзина QWEP',
        youtube: 'https://www.youtube.com/watch?v=zIdb6VVKArE&t',
        rutube: 'https://rutube.ru/video/730477a1c3d0ce327d4eac0bb4305577/',
        type: 'public',
    },
    qwepSearch: {
        description: 'QWEP — Поиск',
        youtube: 'https://www.youtube.com/watch?v=ym-zZCzx3pw',
        rutube: 'https://rutube.ru/video/5ec51eaee51b2af48751929e16ea21c7/',
        type: 'public',
    },
    support: {
        description: 'QWEP — Обращение в поддержку',
        youtube: 'https://www.youtube.com/watch?v=vMuz14G0-MM',
        rutube: 'https://rutube.ru/video/d818f51ef546d1a350fa7e09105f7eab/',
        type: 'public',
    },
    firstRunQwep: {
        description: 'QWEP — Первый запуск и начало работы с QWEP',
        youtube: 'https://www.youtube.com/watch?v=9brRXNi0wNY',
        rutube: 'https://rutube.ru/video/c8f4718347195fc09ddf84f9d0f023ad/',
        type: 'public',
    },
    qwepSearch2: {
        description: 'QWEP — Поиск',
        youtube: 'https://www.youtube.com/watch?v=BwFwG4MF4U8',
        rutube: 'https://rutube.ru/video/71646c2353dbab5735ba3a94c82a61fd/',
        type: 'public',
    },
    refinements: {
        description: 'QWEP — Работа с уточнениями',
        youtube: 'https://www.youtube.com/watch?v=W7D72j_izPM',
        rutube: 'https://rutube.ru/video/d7272efa0c70f48fdeb6178249303e66/',
        type: 'public',
    },
    resultsFiltering: {
        description: 'QWEP — Фильтрация результатов',
        youtube: 'https://www.youtube.com/watch?v=JppARImQyJM',
        rutube: 'https://rutube.ru/video/7afe6e91bdf38e3efd8cd0d0ce343920/',
        type: 'public',
    },
    qwepReview2: {
        description: 'Обзор QWEP',
        youtube: 'https://www.youtube.com/watch?v=0I5r1zpd-EU',
        rutube: 'https://rutube.ru/video/2a80418d659b6bdcec8a3366e7faedb0/',
        type: 'public',
    },
    qwepIntegration: {
        description: 'Интеграция QWEP в приложение «Управление запасами»',
        youtube: 'https://www.youtube.com/watch?v=jBndS37r2F0&t',
        rutube: 'https://rutube.ru/video/d7662757d162504918a022f97a6ba00b/',
        type: 'public',
    },
    qwepWeb: {
        description: 'QWEB WEB',
        youtube: 'https://www.youtube.com/watch?v=MvTy-vlC0Ps',
        rutube: 'https://rutube.ru/video/private/f31143914e33be3a379785d1125d899e/?p=Oe-P00faQX4Sk_tceX0hxA',
        type: 'private',
    },
    maximumCase: {
        description: 'Кейс ГК "Максимум": Как автоматизация проценки от QWEP позволяет сэкономить 7 млн. руб. ежегодно',
        youtube: 'https://www.youtube.com/watch?v=km9R3moxnmQ&t',
        rutube: 'https://rutube.ru/video/b593449e5030ea3e2b4ede643449ebce/',
        type: 'public',
    },
    shinservisCase: {
        description: 'Кейс компании "Шинсервис": Как увеличить продажи на 100% и ускорить проценку запчастей в 10 раз',
        youtube: 'https://www.youtube.com/watch?v=x5J2pFvlCPU',
        rutube: 'https://rutube.ru/video/private/0015b44bc99f2265a70c79ed707603c1/?p=tjMqzt6qILVVZv3jAEePsQ',
        type: 'private',
    },
    shinservisCase2: {
        description: 'Кейс компании "Шинсервис": Как увеличить продажи на 100% и ускорить проценку запчастей в 10 раз',
        youtube: 'https://www.youtube.com/watch?v=heqAXA9mtaY',
        rutube: 'https://rutube.ru/video/0015b44bc99f2265a70c79ed707603c1/',
        type: 'public',
    },
    transportTechnologiesCase: {
        description:
            'Кейс компании "Транспортные технологии": Как снизить стоимость ремонта собственного автопарка на 30%',
        youtube: 'https://www.youtube.com/watch?v=WwFX6Eg0Mhs&t',
        rutube: 'https://rutube.ru/video/e8a746eabf3acbeeb5d851c9b2ce88b6/',
        type: 'public',
    },
    qwepWeb30Instructions: {
        description: 'Инструкция по работе с QWEB WEB 3.0',
        youtube: 'https://www.youtube.com/watch?v=eFEvAhM-QLw',
        rutube: 'https://rutube.ru/video/private/7050497d9966c3a053bbd97f896aafda/?p=jl3_TvBlVSU4wWpkLWjEkA',
        type: 'private',
    },
    qwepWeb30Instructions2: {
        description: 'Инструкция по работе с QWEB WEB 3.0',
        youtube: 'https://www.youtube.com/watch?v=R5kzIzoHX7g',
        rutube: 'https://rutube.ru/video/private/7050497d9966c3a053bbd97f896aafda/?p=jl3_TvBlVSU4wWpkLWjEkA',
        type: 'private',
    },
    orehCase: {
        description: 'Кейс компании "ОРЕХ": автоматизация как точка роста бизнеса',
        youtube: 'https://www.youtube.com/watch?v=7y6FT55gvGE&t',
        rutube: 'https://rutube.ru/video/89e5feb1352ac07e6631848d504b05fb/',
        type: 'public',
    },
    qwepWeb30Instructions3: {
        description: 'Инструкция по работе с QWEB WEB 3.0',
        youtube: 'https://www.youtube.com/watch?v=hb17X3EI1Es',
        rutube: 'https://rutube.ru/video/private/7050497d9966c3a053bbd97f896aafda/?p=jl3_TvBlVSU4wWpkLWjEkA',
        type: 'private',
    },
    qwepDalionAuto: {
        description: 'QWEP и ДАЛИОН:АВТО - плюсы взаимной интеграции',
        youtube: 'https://www.youtube.com/watch?v=ARZlOzauvIg&t',
        rutube: 'https://rutube.ru/video/7e5ec7c1a950522ac5077fba56772325/',
        type: 'public',
    },
    automationForSmallBusiness: {
        description: 'Зачем малому бизнесу нужна автоматизация? Кейс компании "КарДок".',
        youtube: 'https://www.youtube.com/watch?v=S8XhEbl0Xws&t',
        rutube: 'https://rutube.ru/video/3b67d25d650d47b148fc3f66e6e86b13/',
        type: 'public',
    },
    mimsMoscow2023: {
        description: 'Как десант экспертов QWEP захватил MIMS MOSCOW 2023',
        youtube: 'https://www.youtube.com/watch?v=DeBcPD3twMg',
        rutube: 'https://rutube.ru/video/85a2a6972b0159f1010bca730f0f7bcd/',
        type: 'public',
    },
    qwepInterview: {
        description: 'QWEP: взгляд изнутри (интервью на радиостации Mediametrics)',
        youtube: 'https://www.youtube.com/watch?v=fL7kZKteazQ&t',
        rutube: 'https://rutube.ru/video/b0cd84265af9f73b6d4637f56b302414/',
        type: 'public',
    },
    qwepWeb30Instructions4: {
        description: 'Инструкция по работе с QWEB WEB 3.0',
        youtube: 'https://www.youtube.com/watch?v=hNHXfYJC5hE&t',
        rutube: 'https://rutube.ru/play/embed/31fdcc1baae81d1ede47578e665eb760/',
        type: 'public',
    },
    qwepEcosystem: {
        description: 'Первое знакомство с экосистемой QWEP',
        youtube: 'https://www.youtube.com/watch?v=v28QrDg3yl8&t',
        rutube: 'https://rutube.ru/video/f9436ae6ffe76fb057f3190f2a5bbd0f/',
        type: 'public',
    },
    stockReevaluation: {
        description: 'Переоценка склада: инструкция',
        youtube: 'https://www.youtube.com/watch?v=u0AHn7uoNuI',
        rutube: 'https://rutube.ru/video/bb793322f5b6c2058f3a2cab2ba0d7f6/',
        type: 'public',
    },
    assistant: {
        description: 'Ассистент: инструкция',
        youtube: 'https://www.youtube.com/watch?v=PlV4p72B2HU',
        rutube: 'https://rutube.ru/video/24dce80e0976fcae5114d6c05f7ed6f2/',
        type: 'public',
    },
    qwep1CInstructions: {
        description: 'Инструкция по работе с QWEP 1C',
        youtube: 'https://www.youtube.com/watch?v=0d6SLR6q9P0',
        rutube: 'https://rutube.ru/video/5546456f4143f572531de1f0a3f724b6/',
        type: 'public',
    },
    crossesExcelInstructions: {
        description: 'Инструкция по работе с кроссами / Excel - версия',
        youtube: 'https://www.youtube.com/watch?v=oIvU-uDmYGI',
        rutube: 'https://rutube.ru/video/c7080d2a1be13ebe57cd0dae24c96695/',
        type: 'public',
    },
    vinPinInstructions: {
        description: 'Основная инструкция',
        youtube: 'https://www.youtube.com/embed/mDDUkDMGiaw',
        rutube: 'https://rutube.ru/play/embed/68cd8522de8aa4ee25da286d20b874f4/',
        type: 'public',
    },
};
