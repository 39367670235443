import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useUCSContext } from '@f_ucs/context/UCS_context';
import { ThemeContext } from '@f_context/Theme_context';
import { ChartData, ChartOptions, defaults } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useCollector } from '@f_ucs/context/collector_context';
import { useReport } from '@f_ucs/context/report_context';

export type Type1cUser = {
    alias: string
    key: string
}

export type TypeWebUser = {
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    userId: number
}

export type TypeCurrentUsers = TypeWebUser[] | Type1cUser[];

type TypeDoughnutContext = {
    currentUsers: any []
    data1: ChartData<'doughnut'>
    data2: ChartData<'doughnut'>
    options: ChartOptions<'doughnut'>

    //methods
    setCurrentUsers: (value: any []) => void
}

const DoughnutContext = createContext<any>({});

function DoughnutProvider(props: any) {

    const {
        currentType,
        currentCollector,
    } = useCollector();

    const {
        timeCutReports,
    } = useReport();

    const { colors } = useContext(ThemeContext);

    const [violationLabels, setViolationLabels] = useState<string[]>([]);
    const [violationData, setViolationData] = useState<number[]>([]);
    const [lostProfitLabels, setLostProfitLabels] = useState<string[]>([]);
    const [lostProfitData, setLostProfitData] = useState<number[]>([]);
    const [currentUsers, setCurrentUsers] = useState<TypeCurrentUsers>([]);


    const doughnutColors = useMemo(() => [
        colors.red,
        colors.alpha,
        colors.orange,
        colors.gamma,
        colors.yellow,
        colors.nu,
        colors.mu,
        colors.sea,
        colors.sigma,
        colors.kappa,
        colors.xi,
    ], [colors]);

    const calculateData = () => {
        let violationCounts: { [key: string]: number } = {};
        let lostProfitCounts: { [key: string]: number } = {};

        timeCutReports.forEach((item: any) => {
            item.suspiciousBuys.forEach((buy: any) => {
                let key = currentType === 'web' ? buy.user.userId : buy.user.key;

                const minSuitableItemPrice = Math.min(...buy.suitableItems.map((si: any) => si.price));
                const lostProfit = (buy.price - minSuitableItemPrice) * buy.quantity;

                if (!violationCounts[key]) {
                    violationCounts[key] = 0;
                }
                if (!lostProfitCounts[key]) {
                    lostProfitCounts[key] = 0;
                }

                violationCounts[key] += 1;
                lostProfitCounts[key] += lostProfit;

            });
        });

        return {
            violationCounts,
            lostProfitCounts,
        };
    };

    const {
        violationCounts,
        lostProfitCounts,
    } = useMemo(calculateData, [timeCutReports]);

    useEffect(() => {
        if (currentUsers.length) {
            setViolationLabels(updateLabels(Object.keys(violationCounts)));
            setLostProfitLabels(updateLabels(Object.keys(lostProfitCounts)));
            setViolationData(Object.values(violationCounts));
            setLostProfitData(Object.values(lostProfitCounts));
        }
    }, [violationCounts, lostProfitCounts, currentUsers]);

    // Обновление пончика при смене периода времени
    useEffect(() => {
        setData1({
            ...data1,
            labels: violationLabels,
            datasets: [
                {
                    ...data1.datasets[0],
                    data: violationData,
                },
            ],
        });

        setData2({
            ...data2,
            labels: lostProfitLabels,
            datasets: [
                {
                    ...data2.datasets[0],
                    data: lostProfitData,
                },
            ],
        });
    }, [violationLabels, lostProfitLabels, violationData, lostProfitData]);

    useEffect(() => {
        if (currentCollector) {
            getCurrentCollectorUsers();
        }
    }, [currentCollector]);

    const getCurrentCollectorUsers = () => {
        if (currentType === 'web') {
            setCurrentUsers(currentCollector?.tokens as TypeWebUser[]);
        } else {
            setCurrentUsers(currentCollector?.tokens as Type1cUser[]);
        }
    };

    const updateLabels = (labels: string[]): string[] => {
        return labels.map(label => {


            const matchedUser = currentType === 'web'
                ? (currentUsers as TypeWebUser[]).find((user: TypeWebUser) => user.userId && user.userId.toString() === label)
                : (currentUsers as Type1cUser[]).find((user: Type1cUser) => user.key === label);

            if (!matchedUser) {
                return 'Неизвестный пользователь';
            }

            return currentType === 'web'
                ? `${(matchedUser as TypeWebUser).firstName} ${(matchedUser as TypeWebUser).lastName}`
                : (matchedUser as Type1cUser).alias;
        });
    };

    // Первый пончик
    const [data1, setData1] = useState<ChartData<'doughnut'>>({
        labels: violationLabels,
        datasets: [
            {
                label: 'Количество заказов с нарушениями',
                data: violationData,
                backgroundColor: doughnutColors,
                borderColor: colors.delta,
                borderWidth: 2,
            },
        ],
    });

    // Второй пончик
    const [data2, setData2] = useState<ChartData<'doughnut'>>({
        labels: lostProfitLabels,
        datasets: [
            {
                label: 'Объём упущенной выгоды',
                data: lostProfitData,
                backgroundColor: doughnutColors,
                borderColor: colors.delta,
                borderWidth: 1,
            },
        ],
    });

    // Настройки описания к пончикам
    const [options] = useState<ChartOptions<'doughnut'>>({
        responsive: true,
        cutout: '60%',
        radius: '90%',
        plugins: {
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    usePointStyle: true,
                    boxWidth: 7,
                    boxHeight: 7,
                },
            },
            datalabels: {
                formatter: (value: any, ctx: any) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data: any) => {
                        sum += data;
                    });
                    let percentage = ((value * 100) / sum).toFixed(2) + '%';
                    return percentage;
                },
                color: '#fff',
                display: 'auto',
            },
        },
    });

    useEffect(() => {
        defaults.devicePixelRatio = window.devicePixelRatio || 1;
    }, [Doughnut]);

    return (
        <DoughnutContext.Provider
            value={{
                currentUsers,
                data1,
                data2,
                options,
                //methods
                setCurrentUsers,
            } as TypeDoughnutContext}
            {...props}
        />
    );
}

const useDoughnut = (): TypeDoughnutContext => {
    const context = useContext(DoughnutContext);
    if (!context) {
        console.error('DoughnutContext');
    }
    return context as TypeDoughnutContext;
};

export { DoughnutProvider, useDoughnut };
